import React, { useEffect, useRef, useState } from "react";

import Footer from "../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SuccessStoryCarousel from "../Components/Home/SuccessStoryCarousel";
import StickyElements from "../Pages/Training/StickyElements";
import StudentTestimonials from "../Pages/Training/StudentTestimonials"; 
import Clientele from "../Pages/Training/Clientele";
import { useLocation } from 'react-router-dom';
import { useTable, useSortBy } from "react-table";


const WorkshopsPage = () => {
  const webDevelopmentRef = useRef(null);
  const softwareDevelopmentRef = useRef(null);
  const appDevelopmentRef = useRef(null);



  const location = useLocation();

  // Extract the query parameter value
  const searchParams = new URLSearchParams(location.search);
  const flag = searchParams.get('flag');
  useEffect(() => {
    document.title = "Workshops - Think Quotient";
    AOS.init();
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split('?'); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get('scrollTo');

      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    // Rest of your useEffect logic
    console.log("------------->", flag);
    console.log(location.state);
  }, []);



  const pageTitle = "Workshops - ThinkQuotient";
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();



  useEffect(() => {

    AOS.init();

  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");
      console.log("hello " + scrollToSection);
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  
    const [tabIndex1, setTabIndex1] = React.useState(0);
    const [tabIndex2, setTabIndex2] = React.useState(0);
    const [tabIndex3, setTabIndex3] = React.useState(0);

    const data1 = [
      {
        count: "01",
        label: "Trusted brand in fresher's and corporate training, 10th year since inception",
        value: "tab1",
        // image: "/assets/images/Experienced Trainer.jpg",
        // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
      },
  
      {
        count: "02",
        label: "2000+ Freshers successfully placed over 450+ organizations",
        value: "tab2",
        // image: "/assets/images/programming-background-collage.jpg",
        // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
      },
      {
        count: "03",
        label: "Proven expertise in delivering industry-relevant skills.",
        // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
        value: "tab3",
        // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
      },
      {
        count: "04",
        label: "Strong alumni and corporate network ",
        // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
        value: "tab4",
        // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
      },
      {
        count: "05",
        label: "Hands-on training designed for practical application",
        value: "tab5",
        // image: "/assets/images/Capstone Project.jpg",
        // desc: `Foster practical learning through hands-on projects that replicate real-world scenarios. Employees apply their skills to solve problems, building confidence and competence.`,
      },
      {
        count: "06",
        label: "Flexible schedule tailored for working professionals and students",
        // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
        value: "tab6",
        // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
      },
      {
        count: "07",
        label: "Focused guidance on high-demand technologies and frameworks ",
        // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
        value: "tab7",
        // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
      },
      {
        count: "08",
        label: "Exposure to Live Projects",
        value: "tab8",
        // image: "/assets/images/Capstone Project.jpg",
        // desc: `Foster practical learning through hands-on projects that replicate real-world scenarios. Employees apply their skills to solve problems, building confidence and competence.`,
      },
     
    ];
  
    const data2 = [
      {
        count: "01",
        label: "Students who wants to gain knowledge on particular skill",
        value: "tab1",
        // image: "/assets/images/Experienced Trainer.jpg",
        // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
      },
  
      {
        count: "02",
        label: "Working professionals looking to upskill or switch roles",
        value: "tab2",
        // image: "/assets/images/programming-background-collage.jpg",
        // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
      },
      {
        count: "03",
        label: "Developers eager to strengthen their technical foundations",
        // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
        value: "tab3",
        // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
      },
      {
        count: "04",
        label: "Freshers who are willing to start their career",
        // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
        value: "tab4",
        // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
      },
     
    ];
  
    const data3 = [
      {
        count: "01",
        label: "Focus on core concepts",
        value: "tab1",
        // image: "/assets/images/Experienced Trainer.jpg",
        // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
      },
  
      {
        count: "02",
        label: "Frequently asked questions of each technology will be discussed",
        value: "tab2",
        // image: "/assets/images/programming-background-collage.jpg",
        // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
      },
      {
        count: "03",
        label: "Real life examples",
        // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
        value: "tab3",
        // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
      },
      {
        count: "04",
        label: "Online interactive sessions - Learn from anywhere",
        value: "tab4",
        // image: "/assets/images/Experienced Trainer.jpg",
        // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
      },
      {
        count: "05",
        label: "One to one career guidance (on request)",
        // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
        value: "tab5",
        // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
      },{
        count: "06",
        label: "Industry-standard best practices",
        value: "tab6",
        // image: "/assets/images/Experienced Trainer.jpg",
        // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
      },
  
      {
        count: "07",
        label: "Learn from industry experts with years of experience",
        value: "tab7",
        // image: "/assets/images/programming-background-collage.jpg",
        // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
      },
      {
        count: "08",
        label: "Interactive learning approach",
        // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
        value: "tab8",
        // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
      },
      {
        count: "09",
        label: "Low risk high reward",
        // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
        value: "tab9",
        // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
      },
         
    ];

    
      const tabRef = useRef(null); // Create a ref for the tab content
    
    
      const handleTabClick1 = (index) => {
        setTabIndex1(index);
       
      };
    
      const handleTabClick2 = (index) => {
        setTabIndex2(index);
       
      };
      const handleTabClick3 = (index) => {
        setTabIndex3(index);
       
      };

      const tabledata = React.useMemo(
        () => [
          { srNo: 1, code: 'INT-101', topic: 'Technical Interview revision sessions', subtopic: 'Core Java', duration: 8, cost: 999 },
          { srNo: 2, code: 'INT-102', topic: 'Technical Interview revision sessions', subtopic: 'Advanced Java, Spring, Data, MVC, Boot', duration: 8, cost: 999 },
          { srNo: 3, code: 'INT-103', topic: 'Technical Interview revision sessions', subtopic: 'C#', duration: 8, cost: 999 },
          { srNo: 4, code: 'INT-104', topic: 'Technical Interview revision sessions', subtopic: 'Dot Net - MVC, Core', duration: 8, cost: 999 },
          { srNo: 5, code: 'INT-105', topic: 'Technical Interview revision sessions', subtopic: 'SQL, PL/SQL', duration: 8, cost: 499 },
          { srNo: 6, code: 'INT-106', topic: 'Technical Interview revision sessions', subtopic: 'Python', duration: 8, cost: 999 },
          { srNo: 7, code: 'INT-107', topic: 'Technical Interview revision sessions', subtopic: 'Angular', duration: 8, cost: 999 },
          { srNo: 8, code: 'INT-108', topic: 'Technical Interview revision sessions', subtopic: 'React JS', duration: 8, cost: 999 },
          { srNo: 9, code: 'INT-109', topic: 'Softskill - interview revision sessions', subtopic: 'HR interview Questions, Group Discussion, Resume Guidelines, Project Guidance', duration: 8, cost: 999 },
          { srNo: 13, code: 'J-101', topic: 'Java', subtopic: 'OOPs', duration: 8, cost: 999 },
          { srNo: 14, code: 'J-102', topic: 'Java', subtopic: 'String, Array', duration: 4, cost: 999 },
          { srNo: 16, code: 'J-104', topic: 'Java', subtopic: 'Collection', duration: 8, cost: 999 },
          { srNo: 17, code: 'J-105', topic: 'Java', subtopic: 'Exception Handling, inner classes, Wrapper classes', duration: 8, cost: 999 },
          { srNo: 18, code: 'J-106', topic: 'Java', subtopic: 'JCBC, Design Patterns', duration: 8, cost: 999 },
          { srNo: 19, code: 'J-107', topic: 'Java', subtopic: 'MultiThreading, File Handling', duration: 8, cost: 999 },
          { srNo: 20, code: 'J-108', topic: 'Java', subtopic: 'Java new features', duration: 8, cost: 999 },
          { srNo: 21, code: 'SP-101', topic: 'Spring', subtopic: 'Spring Framework', duration: 4, cost: 999 },
          { srNo: 22, code: 'SP-102', topic: 'Spring', subtopic: 'Spring data and ORM', duration: 4, cost: 999 },
          { srNo: 23, code: 'SP-103', topic: 'Spring', subtopic: 'Web MVC', duration: 4, cost: 999 },
          { srNo: 24, code: 'SP-104', topic: 'Spring', subtopic: 'Spring Boot using rest API', duration: 4, cost: 999 },
          { srNo: 25, code: 'SQ-101', topic: 'SQL', subtopic: 'Fundamental,Normalization, Queries', duration: 4, cost: 999 },
          { srNo: 26, code: 'SQ-102', topic: 'SQL', subtopic: 'SubQuery, Joins', duration: 4, cost: 999 },
          { srNo: 27, code: 'SQ-103', topic: 'PL/SQL', subtopic: 'Procedures, Functions, Triggers', duration: 4, cost: 999 },
          { srNo: 28, code: 'AN-101', topic: 'Angular', subtopic: 'Architecture, Typescript, Components, Directives, Pipes', duration: 4, cost: 999 },
          { srNo: 29, code: 'RC-101', topic: 'React JS', subtopic: 'Architecture, JavaScript and ES6 Essentials, Basics', duration: 4, cost: 999 },
          { srNo: 30, code: 'PY-101', topic: 'Python', subtopic: 'Python Data Structures', duration: 4, cost: 999 },
          { srNo: 31, code: 'QA-101', topic: 'QA', subtopic: 'Manual Testing', duration: 4, cost: 999 },
          { srNo: 35, code: 'RC-101', topic: 'React Native', subtopic: 'Core Concepts, Navigation, State Management, Forms, Styling, Rest APIs', duration: 4, cost: 999 },
          { srNo: 36, code: 'GEN-101', topic: 'Generative AI', subtopic: 'Python, ML, Data Science', duration: 400, cost: 30000 },
          { srNo: 37, code: 'KNM-101', topic: 'Germany based KNIME Data Analytics', subtopic: 'Fundamental programs to clear KNIME L1,L2 certification', duration: 100, cost: 19000 },
          { srNo: 38, code: 'FS-101', topic: 'Intelligence based Full Stack Program', subtopic: 'Java,Spring ,Dot Net,Angular, QA', duration: 500, cost: 49999 }
        ],
        []
      );
    
      const columns = React.useMemo(
        () => [
          { Header: 'Sr. No.', accessor: 'srNo' },
          { Header: 'Code', accessor: 'code' },
          { Header: 'Topic', accessor: 'topic' },
          { Header: 'Subtopic', accessor: 'subtopic' },
          { 
            Header: () => (
              <span>
                Duration <br /> (in hrs)
              </span>
            ), 
            accessor: 'duration' 
          },
          { 
            Header: () => (
              <span>
                Cost<br /> (Rs.)
              </span>
            ), 
            accessor: 'cost' 
          },
          
        ],
        []
      );
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
      } = useTable(
        {
          columns,
          data: tabledata,  
        },
        useSortBy  // Enables sorting functionality
      );

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>{pageTitle}</title>
        <meta name="title" content={metaTitle} />

        {/* Meta Tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Social Media Sharing */}
        <meta property="og:title" content={metaTitle || "Campaign - ThinkQuotient"} />
        <meta
          property="og:description"
          content="Discover ThinkQuotient's IT training campaigns. We offer tailored courses to help you excel in web, software, and app development with placement support."
        />
        <meta
          property="og:image"
          content="https://www.thinkquotient.com/assets/images/campaign-banner.png"
        />
        <meta property="og:url" content="https://www.thinkquotient.com/campaign" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle || "Campaign - ThinkQuotient"} />
        <meta
          name="twitter:description"
          content="Join ThinkQuotient's IT campaigns and explore training in web, software, and app development. Placement assistance guaranteed!"
        />
        <meta
          name="twitter:image"
          content="https://www.thinkquotient.com/assets/images/campaign-banner.png"
        />

        {/* Robots and Author */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Think Quotient" />

        {/* Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
        `}
        </script>

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
        `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
        `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
        `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>{`
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      `}</noscript>
      </Helmet>



      <div className="overflow-hidden">


        <div className="flex flex-col items-center py-14   px-6 campain-banner-sec ">

          {/* Logo Section */}
          <a href="https://www.thinkquotient.com" rel="noopener noreferrer">
            <img
              src="/assets/images/tq-logo.png"
              alt="Logo"
              className="absolute top-4 left-4 h-16 w-auto mb-4 "
            />
          </a>
          <h1
            className="text-base sm:text-lg font-normal container mx-auto text-center color-[#ffffff]"
          >
            <div>
              <p className="font-bold text-xl sm:text-2xl mt-6">
              Accelerate Your Career with ThinkQuotient's Workshops{" "} 
              </p>
            </div>
            <br />These mini live workshops will help you clear your concepts and will increase your chances to clear the interviews. 
            <br />High quality training with low cost.
          </h1>
        </div>

         <div className="flex flex-col items-center pt-14   fade-in-down px-6">
                 <h1
                   className="text-lg font-normal container mx-auto text-center"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                 >
                   <div>
                     <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                     Why ThinkQuotient {" "}
                     </p>
                   </div>
                 </h1>
               </div>
       
               <div
       
                 className="container  mx-auto py-14 px-4 hidden  lg:block"
                 ref={tabRef} >
                 <Tabs
                   selectedIndex={tabIndex1}
                   onSelect={(index) => setTabIndex1(index)}
                   className="block md:flex"
                 >
                   <TabList
                     className="rounded-none bg-transparent p-0 tablist-div w-full"
                     indicatorProps={{
                       className: "bg-transparent shadow-none rounded-none",
                     }}
                     data-aos="fade-right"
                     data-aos-duration="1000"
                   >
                     {data1.map(({ label }, index) => (
                       <Tab
                         key={index}
                         onClick={() => setTabIndex1(index)} // Update tabIndex on click
                         className={`${tabIndex1 === index
                             ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                             : "text-gray-500 py-2 px-4"
                           } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                         style={{
                           cursor: "pointer",
                           color: tabIndex1 === index ? "blue" : "inherit", // Inline style for color
                         }}
                       >
                         <a
                           href="#slide-sec"
                           onClick={(event) => event.preventDefault()}
                         >
                           <p
                             className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-2 ${tabIndex1 === index ? "text-blue-500" : "text-gray-500"
                               }`}
                           >
                             <span
                               className="bullet-point"
                               style={{
                                 width: "3px", // Thin vertical line
                                 height: "30px", // Length of the vertical line
                                 backgroundColor: "#01a8ef", // custom color for the line
                                 display: "inline-block",
                                 marginRight: "1rem",
                                 borderRadius: "0", // Keep it a straight line with no rounded corners
                               }}
                             ></span>
                             {label}
                           </p>
                         </a>
                       </Tab>
                     ))}
                   </TabList>
       
                   {/* <div className="tab-content md:w-1/2 p-4" id="slide-sec">
                     {data[tabIndex] && (
                       <motion.div
                         key={tabIndex} // Re-run animation on tabIndex change
                         initial="initial"
                         animate="animate"
                         exit="exit"
                         variants={fadeVariants}
                         className="relative w-full"
                       >
                         <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                           <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                             <motion.img
                               src={data[tabIndex].image}
                               alt={`${data[tabIndex].label} image`}
                               className="object-cover w-full h-full rounded-lg"
                               initial={{ opacity: 0 }}
                               animate={{ opacity: 1, transition: { duration: 0.5 } }}
                             />
                           </div>
       
                           <motion.div
                             // className="bg-white shadow-lg p-8 rounded-lg w-full md:w-[calc(100%--3rem)] absolute top-[7rem] left-[12rem] md:h-auto overflow-auto"
                             className="custom-card"
                             initial={{ opacity: 0 }}
                             animate={{ opacity: 1, transition: { duration: 0.5 } }}
                           >
                             <p className="text-md md:text-lg">
                               {data[tabIndex].desc}
                             </p>
                           </motion.div>
                         </div>
                       </motion.div>
                     )}
                   </div>*/}
                 </Tabs> 
               </div>
       
       
       
               <div
                 ref={tabRef}
                 className="container mx-auto py-10 px-4 md:block lg:hidden">
                 <Tabs
                   selectedIndex={tabIndex1}
                   onSelect={(index) => setTabIndex1(index)}
                   className="block md:flex"
                 >
                   <TabList
                     className="rounded-none bg-transparent p-0 tablist-div w-full"
                     indicatorProps={{
                       className: "bg-transparent shadow-none rounded-none",
                     }}
                     data-aos="fade-right"
                     data-aos-duration="1000"
                   >
                     {data1.map(({ label }, index) => (
                       <Tab
                         key={index}
                         onClick={() => handleTabClick1(index)}// Update tabIndex on click
                         className={`${tabIndex1 === index
                             ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                             : "text-gray-500 py-2 px-4"
                           }`}
                         style={{
                           cursor: "pointer",
                         }}
                       >
                         <a
                           href="#slide-sec"
                           onClick={(event) => event.preventDefault()}
                         >
                           <p
                             className={`md:ps-14 ps-4 font-semibold md:w-full text-base md:text-xl mb-2 ${tabIndex1 === index ? "text-blue-500" : "text-gray-500"
                               }`}
                           >
                             <span
                               className="bullet-point"
                               style={{
                                 width: "3px", // Thin vertical line
                                 height: "30px", // Length of the vertical line
                                 backgroundColor: "#01a8ef", // custom color for the line
                                 display: "inline-block",
                                 marginRight: "1rem",
                                 borderRadius: "0", // Keep it a straight line with no rounded corners
                               }}
                             ></span>
                             {label}
                           </p>
                         </a>
                       </Tab>
                     ))}
                   </TabList>
       
                   {/* <div ref={scrollRef} className="tab-content md:w-1/2 p-4" id="slide-sec" style={{ scrollMarginTop: "8.5rem" }}>
                     {data[tabIndex] && (
                       <div className="relative w-full">
                       
                         <div className="flex flex-col md:flex-col items-center md:items-start">
                           
                           <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                             <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                               <img
                                 src={data[tabIndex].image}
                                 alt={`${data[tabIndex].label} image`}
                                 className="object-cover w-full h-full rounded-lg"
                               />
                             </div>
                           </div>
       
                        
                           <div className="bg-white shadow-lg p-4 rounded-lg w-full md:w-[calc(100%-1rem)] md:ml-0 mt-4 md:mt-0">
                             <p className="text-md md:text-lg">
                               {data[tabIndex].desc}
                             </p>
                           </div>
                         </div>
                       </div>
                     )}
                   </div> */}
                 </Tabs>
               </div>
       
             {/* Who can attend section */}
               <div className="flex flex-col items-center pt-14   fade-in-down px-6">
                 <h1
                   className="text-lg font-normal container mx-auto text-center"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                 >
                   <div>
                     <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                     Who can attend{" "}
                     </p>
                   </div>
                 </h1>
               </div>
       
               <div
       
                 className="container  mx-auto py-14 px-4 hidden  lg:block"
               >
                 <Tabs
                   selectedIndex={tabIndex2}
                   onSelect={(index) => setTabIndex2(index)}
                   className="block md:flex"
                 >
                   <TabList
                     className="rounded-none bg-transparent p-0 tablist-div w-full"
                     indicatorProps={{
                       className: "bg-transparent shadow-none rounded-none",
                     }}
                     data-aos="fade-right"
                     data-aos-duration="1000"
                   >
                     {data2.map(({ label }, index) => (
                       <Tab
                         key={index}
                         onClick={() => setTabIndex2(index)} // Update tabIndex on click
                         className={`${tabIndex2 === index
                             ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                             : "text-gray-500 py-2 px-4"
                           } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                         style={{
                           cursor: "pointer",
                           color: tabIndex2 === index ? "blue" : "inherit", // Inline style for color
                         }}
                       >
                         <a
                           href="#slide-sec"
                           onClick={(event) => event.preventDefault()}
                         >
                           <p
                             className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-2 ${tabIndex2 === index ? "text-blue-500" : "text-gray-500"
                               }`}
                           >
                             <span
                               className="bullet-point"
                               style={{
                                 width: "3px", // Thin vertical line
                                 height: "30px", // Length of the vertical line
                                 backgroundColor: "#01a8ef", // custom color for the line
                                 display: "inline-block",
                                 marginRight: "1rem",
                                 borderRadius: "0", // Keep it a straight line with no rounded corners
                               }}
                             ></span>
                             {label}
                           </p>
                         </a>
                       </Tab>
                     ))}
                   </TabList>
                 </Tabs> 
               </div>
       
       
       
               <div
                 className="container mx-auto py-10 px-4 md:block lg:hidden">
                 <Tabs
                   selectedIndex={tabIndex2}
                   onSelect={(index) => setTabIndex2(index)}
                   className="block md:flex"
                 >
                   <TabList
                     className="rounded-none bg-transparent p-0 tablist-div w-full"
                     indicatorProps={{
                       className: "bg-transparent shadow-none rounded-none",
                     }}
                     data-aos="fade-right"
                     data-aos-duration="1000"
                   >
                     {data2.map(({ label }, index) => (
                       <Tab
                         key={index}
                         onClick={() => handleTabClick2(index)}// Update tabIndex on click
                         className={`${tabIndex2 === index
                             ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                             : "text-gray-500 py-2 px-4"
                           }`}
                         style={{
                           cursor: "pointer",
                         }}
                       >
                         <a
                           href="#slide-sec"
                           onClick={(event) => event.preventDefault()}
                         >
                           <p
                             className={`md:ps-14 ps-4 font-semibold md:w-full text-base md:text-xl mb-2 ${tabIndex2 === index ? "text-blue-500" : "text-gray-500"
                               }`}
                           >
                             <span
                               className="bullet-point"
                               style={{
                                 width: "3px", // Thin vertical line
                                 height: "30px", // Length of the vertical line
                                 backgroundColor: "#01a8ef", // custom color for the line
                                 display: "inline-block",
                                 marginRight: "1rem",
                                 borderRadius: "0", // Keep it a straight line with no rounded corners
                               }}
                             ></span>
                             {label}
                           </p>
                         </a>
                       </Tab>
                     ))}
                   </TabList>
       
                 </Tabs>
               </div>
       
       
                  {/* Benefits section */}
                  <div className="flex flex-col items-center pt-14   fade-in-down px-6">
                 <h1
                   className="text-lg font-normal container mx-auto text-center"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                 >
                   <div>
                     <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                     Benefits {" "}
                     </p>
                   </div>
                 </h1>
               </div>
       
               <div
       
                 className="container  mx-auto py-14 px-4 hidden  lg:block"
               >
                 <Tabs
                   selectedIndex={tabIndex3}
                   onSelect={(index) => setTabIndex3(index)}
                   className="block md:flex"
                 >
                   <TabList
                     className="rounded-none bg-transparent p-0 tablist-div w-full"
                     indicatorProps={{
                       className: "bg-transparent shadow-none rounded-none",
                     }}
                     data-aos="fade-right"
                     data-aos-duration="1000"
                   >
                     {data3.map(({ label }, index) => (
                       <Tab
                         key={index}
                         onClick={() => setTabIndex3(index)} // Update tabIndex on click
                         className={`${tabIndex3 === index
                             ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                             : "text-gray-500 py-2 px-4"
                           } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                         style={{
                           cursor: "pointer",
                           color: tabIndex3 === index ? "blue" : "inherit", // Inline style for color
                         }}
                       >
                         <a
                           href="#slide-sec"
                           onClick={(event) => event.preventDefault()}
                         >
                           <p
                             className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-2 ${tabIndex3 === index ? "text-blue-500" : "text-gray-500"
                               }`}
                           >
                             <span
                               className="bullet-point"
                               style={{
                                 width: "3px", // Thin vertical line
                                 height: "30px", // Length of the vertical line
                                 backgroundColor: "#01a8ef", // custom color for the line
                                 display: "inline-block",
                                 marginRight: "1rem",
                                 borderRadius: "0", // Keep it a straight line with no rounded corners
                               }}
                             ></span>
                             {label}
                           </p>
                         </a>
                       </Tab>
                     ))}
                   </TabList>
                 </Tabs> 
               </div>
       
       
       
               <div
                 className="container mx-auto py-10 px-4 md:block lg:hidden">
                 <Tabs
                   selectedIndex={tabIndex3}
                   onSelect={(index) => setTabIndex3(index)}
                   className="block md:flex"
                 >
                   <TabList
                     className="rounded-none bg-transparent p-0 tablist-div w-full"
                     indicatorProps={{
                       className: "bg-transparent shadow-none rounded-none",
                     }}
                     data-aos="fade-right"
                     data-aos-duration="1000"
                   >
                     {data3.map(({ label }, index) => (
                       <Tab
                         key={index}
                         onClick={() => handleTabClick3(index)}// Update tabIndex on click
                         className={`${tabIndex3 === index
                             ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                             : "text-gray-500 py-2 px-4"
                           }`}
                         style={{
                           cursor: "pointer",
                         }}
                       >
                         <a
                           href="#slide-sec"
                           onClick={(event) => event.preventDefault()}
                         >
                           <p
                             className={`md:ps-14 ps-4 font-semibold md:w-full text-base md:text-xl mb-2 ${tabIndex3 === index ? "text-blue-500" : "text-gray-500"
                               }`}
                           >
                             <span
                               className="bullet-point"
                               style={{
                                 width: "3px", // Thin vertical line
                                 height: "30px", // Length of the vertical line
                                 backgroundColor: "#01a8ef", // custom color for the line
                                 display: "inline-block",
                                 marginRight: "1rem",
                                 borderRadius: "0", // Keep it a straight line with no rounded corners
                               }}
                             ></span>
                             {label}
                           </p>
                         </a>
                       </Tab>
                     ))}
                   </TabList>
       
                 </Tabs>
               </div>
       
       
               <div className="flex flex-col items-center pt-14   fade-in-down px-6">
                 <h1
                   className="text-lg font-normal container mx-auto text-center"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                 >
                   <div>
                     <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                     List of Workshops{" "}
                     </p>
                   </div>
                 </h1>
                 
               </div>
       
                   {/* Table Section */}
                  
                   <div className="bg-white py-8 pt-6 mt-4 mb-8 mx-2 md:mx-4 lg:mx-6"
                     data-aos="fade-up"
                     data-aos-duration="1000"
                   >
                   <div className="p-4 overflow-x-auto mb-4">
                     <table {...getTableProps()} className="min-w-full table-auto bg-white rounded-lg shadow-lg border border-blue-600" >
                        <thead style={{ backgroundColor: 'rgb(33, 68, 145)' }}>
                            {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="py-3 px-4 text-center text-sm md:text-lg font-semibold text-white border-b border-blue-300"
                                   
                                >
                                    {column.render('Header')}
                                    <span>
                                    {sortBy.length > 0
                                        ? column.isSorted
                                        ? column.isSortedDesc
                                           ? ' 🔽'
                                            : ' 🔼'
                                        : ''
                                        : ''}
                                    </span>
                                </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="border-b border-blue-200 hover:bg-blue-50">
                                {row.cells.map(cell => {
                                    const cellClass = cell.column.id === 'topic' || cell.column.id === 'subtopic' 
                                    ? 'py-2 text-sm text-blue-800 text-left sm:pl-2 md:p-2 lg:p-4' 
                                    : 'py-2 text-sm text-blue-800 text-center sm:pl-2 md:p-2 lg:p-4';
                                    return(
                                    <td {...cell.getCellProps()} className={cellClass}>
                                    {cell.render('Cell')}
                                    </td>
                                    )
                            })}
                                </tr>
                            );
                            })}
                        </tbody>
                        </table>       
           
                       </div>
                   </div>  


        <Clientele />
        <SuccessStoryCarousel />
        <StudentTestimonials />
        <Footer />
        <StickyElements />

        <div className="fixed bottom-4 right-4 z-50 m-4">
            <a
                href="https://forms.gle/your-google-form-link" // Replace with your actual Google Form link
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-500 text-white p-4 rounded-md shadow-lg hover:bg-blue-600"
            >
                Register for Workshop
            </a>
        </div>
      </div >
    </>
  );
};

export default WorkshopsPage;
